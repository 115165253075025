import { defineStore } from 'pinia'
import type { UserState } from './types'
import { useAuthentication } from 'sima-suite-authentication'

export const useUserStore = defineStore({
  id: 'userStore',
  state: (): UserState => ({
    user: {
      name: '',
      email: ''
    },
    canManageAppointment: false,
    isServiceKeyVisible: false
  }),
  actions: {
    async getUserProfile() {
      const { getUserProfile } = useAuthentication()
      this.user = await getUserProfile()
    }
  }
})
