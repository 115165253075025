<template>
  <svg width="67" height="68" viewBox="0 0 67 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ii_25_3925)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5321 3.77475C20.3331 6.44462 14.9738 9.41124 3.85297 10.7858C1.14666 11.1203 0.109283 12.3573 0.0270627 14.9456C-1.04477 48.6872 30.0776 67.0808 30.0776 67.0808C32.4601 68.2016 33.7526 68.3118 35.9423 67.0808C35.9423 67.0808 67.0647 48.6872 65.9929 14.9456C65.9106 12.3573 64.8732 11.1203 62.1669 10.7858C51.0461 9.41124 46.3804 6.44462 42.1814 3.77475C34.3942 -1.17659 31.6713 -1.33926 24.5321 3.77475Z"
        fill="#12B5AB"
      />
    </g>
    <g filter="url(#filter1_di_25_3925)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49.5572 21.7801C51.4383 23.8942 51.2734 27.1545 49.189 29.0623L33.156 43.7374C31.2525 45.4796 28.3678 45.5115 26.4273 43.8118L17.8242 36.2759C15.6993 34.4147 15.4644 31.1587 17.2995 29.0036C19.1347 26.8484 22.3449 26.6102 24.4698 28.4714L29.6762 33.032L42.3773 21.4067C44.4617 19.4988 47.6762 19.666 49.5572 21.7801Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <filter
        id="filter0_ii_25_3925"
        x="-2"
        y="-2"
        width="70.0199"
        height="71.9632"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-2" dy="2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_25_3925" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2" dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0445312 0 0 0 0 0.5625 0 0 0 0 0.530723 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_25_3925"
          result="effect2_innerShadow_25_3925"
        />
      </filter>
      <filter
        id="filter1_di_25_3925"
        x="14.0632"
        y="18.0784"
        width="36.8036"
        height="29.9872"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="-1" dy="2" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0431373 0 0 0 0 0.560784 0 0 0 0 0.529412 0 0 0 1 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_25_3925" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_25_3925" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-2" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0431373 0 0 0 0 0.560784 0 0 0 0 0.529412 0 0 0 0.46 0"
        />
        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_25_3925" />
      </filter>
    </defs>
  </svg>
</template>
