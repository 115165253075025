<template>
  <s-sheet
    :elevation="SElevationTokens.s"
    :rounded="SRoundedTokens.mr"
    class="bg-color-white p-spacing-s"
  >
    <s-main-toolbar :title="title" />
    <dashboard-lead-list
      v-if="leads.length"
      :items="leads"
      :color-chip="colorChip"
      :is-loading="isLoading"
      @item-click="handleItemClick"
    />
    <div v-else class="flex h-full items-center justify-center">
      <s-visual-context
        :title="translate('Dashboard.Inbox.Clean.Title')"
        :src="image.src"
        :alt="image.alt"
        :subtitle="translate('Dashboard.Inbox.Clean.Content')"
      />
    </div>
  </s-sheet>
</template>

<script setup lang="ts">
import {
  SElevationTokens,
  SRoundedTokens,
  SSheet,
  SMainToolbar,
  SVisualContext
} from 'sima-suite-components-vue3'
import { useTranslator } from 'sima-suite-translator-vue3'
import { type IGetLeadsQueryResult } from 'lead-tray-api-ts'
import CleanInboxLogo from '@/assets/CleanInboxLogo.svg'
import { computed } from 'vue'
import DashboardLeadList from '@/components/Molecules/DashboardLeadList/DashboardLeadList.vue'

const image = computed(() => {
  return { src: CleanInboxLogo, alt: translate('Dashboard.Inbox.Clean.Title') }
})

type leadsCardProps = {
  leads: IGetLeadsQueryResult[]
  title: string
  colorChip: string
  isLoading: boolean
}

defineProps<leadsCardProps>()

const { translate } = useTranslator()

const emit = defineEmits<{
  (e: 'leadClick', lead: IGetLeadsQueryResult): void
}>()
const handleItemClick = (lead: IGetLeadsQueryResult) => {
  emit('leadClick', lead)
}
</script>
