<script setup lang="ts">
import { computed } from 'vue'

type SCircleKpiProps = {
  value: number
  title: string
  circleColor?: string
}

export type CircleKpiItem = {
  value: number
  title: string
}

const MAX_VALUE = 999

const props = withDefaults(defineProps<SCircleKpiProps>(), {
  circleColor: '--color-primary-500'
})

const emit = defineEmits<{
  (event: 'click', value: CircleKpiItem): void
}>()

const handleClick = () => {
  emit('click', { value: props.value, title: props.title })
}

const isHigherThanMaxValue = computed(() => {
  return props.value > MAX_VALUE
})

const formatValue = computed(() => {
  return isHigherThanMaxValue.value ? MAX_VALUE : props.value
})

const computedCircleColor = computed(() => {
  if (props.circleColor.includes('--')) {
    return `var(${props.circleColor})`
  }

  return props.circleColor
})
</script>

<template>
  <div class="text-center" @click="handleClick">
    <div class="font-work-sans circle">
      <span v-if="value > MAX_VALUE" class="text-2xl">+</span
      ><span class="text-4xl">{{ formatValue }}</span>
    </div>
    <div class="b2-typography text-color-neutral-450 pt-spacing-xs">
      <span>{{ title }}</span>
    </div>
  </div>
</template>

<style scoped>
@font-face {
  font-family: work-sans;
  src: url(./fonts/WorkSans-Light.ttf);
}

.font-work-sans {
  font-family: 'work-sans', sans-serif;
}

.circle {
  @apply text-color-neutral-400 flex h-24 w-24 items-center justify-center rounded-full border-4 border-solid;
  border-color: v-bind(computedCircleColor);
}
</style>
