<script lang="ts">
import { onMounted } from 'vue'
import { useAuthentication } from 'sima-suite-authentication'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    onMounted(async () => {
      try {
        const { redirect, logout } = useAuthentication()
        const usr = await redirect()
        const isEmptyUser = JSON.stringify(usr) === JSON.stringify({})
        if (isEmptyUser) {
          return logout()
        }
        router.push('/')
      } catch (error) {
        console.error(error)
      }
    })
  }
}
</script>

<template>
  <div />
</template>
