import { defineStore } from 'pinia'
import type { State } from '@/store/Dashboard/types'
import {
  DashboardClient,
  FilterOperator,
  FilterValue,
  LeadsClient,
  SortDirection,
  SortValue
} from 'lead-tray-api-ts'
import { createEmptyAgentStatistics, createEmptyPerformanceDto } from '@/utils'
import { useTranslator } from 'sima-suite-translator-vue3'
import type { ChartSections } from '@/components/Atoms/PerformanceChart/PerformanceChart.vue'
const { translate } = useTranslator()

let leadsClient: LeadsClient
export const initDashboardLeadsClient = (client: LeadsClient) => {
  leadsClient = client
}

let dashboardClient: DashboardClient
export const initDashboardClient = (dashboard: DashboardClient) => {
  dashboardClient = dashboard
}

export const useDashboardStore = defineStore({
  id: 'dashboard',
  state: (): State => ({
    leadsToExpire: [],
    leadsReactivate: [],
    agentPerformance: createEmptyPerformanceDto(),
    teamPerformance: createEmptyPerformanceDto(),
    agentStatistics: createEmptyAgentStatistics()
  }),
  actions: {
    async getLeadsToExpire() {
      const expirationDateSort: SortValue = new SortValue({
        propertyName: 'expirationDate',
        sortDirection: SortDirection.Ascending
      })
      const statusFilter: FilterValue = new FilterValue({
        propertyName: 'leadStatusId',
        value: '0,1,2',
        filterOperator: FilterOperator.In
      })
      const { data } = await leadsClient.get(null, [statusFilter], [expirationDateSort], 1, 8)
      this.leadsToExpire = data!
    },
    async getReactivateLeads() {
      const expirationDateSort: SortValue = new SortValue({
        propertyName: 'expirationDate',
        sortDirection: SortDirection.Ascending
      })
      const statusFilter: FilterValue = new FilterValue({
        propertyName: 'leadStatusId',
        value: '2',
        filterOperator: FilterOperator.Eq
      })

      const today = new Date().toISOString().split('T')[0]

      const postponedDateFilter: FilterValue = new FilterValue({
        propertyName: 'postponedDate',
        value: today,
        filterOperator: FilterOperator.Eq
      })
      const { data } = await leadsClient.get(
        null,
        [statusFilter, postponedDateFilter],
        [expirationDateSort],
        1,
        8
      )
      this.leadsReactivate = data!
    },
    async getEffectiveness() {
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      const startDate = new Date(currentDate)
      currentDate.setHours(23, 59, 59, 999)
      const endDate = new Date(currentDate)

      const { agentPerformance, teamPerformance } = await dashboardClient.getPerformance(
        startDate,
        endDate
      )
      this.agentPerformance = agentPerformance
      this.teamPerformance = teamPerformance
    },
    async getAgentStatistics() {
      this.agentStatistics = await dashboardClient.getAgentStatistics()
    }
  },
  getters: {
    performance(): ChartSections {
      return {
        info: [
          {
            name: translate('Dashboard.Performance.Graphic.Section.Expired.Label'),
            color: '#ebebec'
          },
          {
            name: translate('Dashboard.Performance.Graphic.Section.CloseWithoutAppointment.Label'),
            color: '#fe8e80'
          },
          {
            name: translate('Dashboard.Performance.Graphic.Section.CloseWithAppointment.Label'),
            color: '#12b5ab'
          }
        ],
        data: [
          [this.agentPerformance.expiredLeads, this.teamPerformance.expiredLeads],
          [this.agentPerformance.cancelledLeads, this.teamPerformance.cancelledLeads],
          [this.agentPerformance.scheduledLeads, this.teamPerformance.scheduledLeads]
        ],
        xAxisLabels: [
          translate('Dashboard.Performance.Graphic.Agent.Label'),
          translate('Dashboard.Performance.Graphic.Team.Label')
        ]
      }
    }
  }
})
