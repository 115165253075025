import { useAuthentication } from 'sima-suite-authentication'
import fetchIntercept from 'fetch-intercept'

export function initIntercept(backendUrl: string) {
  const { logout, getAccessToken } = useAuthentication()
  fetchIntercept.register({
    request: function (url, config) {
      if (url.includes(backendUrl)) config.headers.Authorization = `Bearer ${getAccessToken()}`
      return [url, config]
    },
    response: function (response) {
      if (response.status === 401) {
        logout()
      }
      return response
    },
    responseError: function (error) {
      if (error.status === 401) {
        logout()
      }
      return Promise.reject(error)
    }
  })
}
