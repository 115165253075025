<template>
  <s-app>
    <router-view />
    <div class="absolute bottom-0 w-full">
      <s-alerts-handler :messages="messages" @alert-message-closed="removeAlert" />
    </div>
  </s-app>
</template>

<script setup lang="ts">
import { SAlertsHandler, SApp } from 'sima-suite-components-vue3'
import { useAlert } from '@/composables'
const { removeAlert, messages } = useAlert()
</script>
