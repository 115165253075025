<template>
  <s-sheet
    :elevation="SElevationTokens.s"
    :rounded="SRoundedTokens.mr"
    class="bg-color-white p-spacing-s"
  >
    <h3 class="h3-typography w-full">
      {{ title }}
    </h3>
    <div class="flex flex-col items-center justify-center">
      <div class="flex items-center justify-center gap-spacing-s">
        <div class="h-16 w-16">
          <slot name="icon">
            <default-icon />
          </slot>
        </div>
        <div class="text-color-neutral-400">
          <span v-if="showPlusSymbol" class="text-[2.5rem]">+</span>
          <span class="font-work-sans text-[4.125rem]"> {{ parsedValue }} </span>
          <span v-if="unit" class="font-work-sans text-[2.5rem]">{{ unit }}</span>
        </div>
      </div>
      <span class="b2-typography text-color-neutral-300">{{ subtitle }}</span>
    </div>
  </s-sheet>
</template>

<script setup lang="ts">
import { SElevationTokens, SRoundedTokens, SSheet } from 'sima-suite-components-vue3'
import { computed } from 'vue'
import DefaultIcon from '@/components/Atoms/SIconKpi/DefaultIcon.vue'

type SIconKpi = {
  title: string
  subtitle: string
  value: number
  unit?: string
}

const MAX_NUMBER = 9999

const props = withDefaults(defineProps<SIconKpi>(), {
  unit: ''
})

const showPlusSymbol = computed(() => !props.unit && props.value > MAX_NUMBER)

const parsedValue = computed(() => {
  return props.value > MAX_NUMBER ? MAX_NUMBER : props.value
})
</script>

<style scoped>
@font-face {
  font-family: work-sans;
  src: url(./fonts/WorkSans-Light.ttf);
}

.font-work-sans {
  font-family: 'work-sans', sans-serif;
}
</style>
