import { provideAuthentication } from 'sima-suite-authentication'
import type { Settings } from './types'
import type { Theme } from 'node_modules/sima-suite-components-vue3/dist/src/plugins/types'

export async function loadConfiguration() {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
  provideAuthentication({
    authority: '',
    clientId: '',
    redirectLogin: '',
    redirectLogout: '',
    silentRenew: '',
    scope: ''
  })
  if (import.meta.env.PROD) {
    const request = await fetch('./frontendconfiguration.json', { headers })
    // here goes the additional async calls you may need to do for additional settings
    return await request.json()
  }
  return configureDevelopmentSettings()
}

function configureDevelopmentSettings(): Settings {
  const env = import.meta.env
  return {
    backendUrl: env.VITE_BACKEND_URL,
    hotjarKey: env.VITE_HOTJAR_KEY,
    gtmKey: env.VITE_GTM_KEY,
    sentryKey: env.VITE_SENTRY_AUTH_TOKEN,
    authentication: {
      authority: env.VITE_AUTH_AUTHORITY,
      clientId: env.VITE_AUTH_CLIENT_ID,
      redirectLogin: env.VITE_AUTH_REDIRECT_LOGIN,
      redirectLogout: env.VITE_AUTH_REDIRECT_LOGOUT,
      silentRenew: env.VITE_AUTH_SILENT_RENEW,
      scope: env.VITE_AUTH_SCOPE
    },
    productFruitsWorkspaceKey: env.VITE_PRODUCT_FRUIT_KEY,
    canManageAppointment: env.VITE_CAN_MANAGE_APPOINTMENT === 'true',
    isServiceKeyVisible: env.VITE_IS_SERVICE_KEY_VISIBLE === 'true'
  }
}

export const themeProduct: Theme = {
  themeName: 'light',
  theme: {
    dark: false,
    colors: {
      primary: '#48A488',
      background: '#FAFAFA'
    }
  }
}
