<template>
  <v-chart class="chart" :option="option" autoresize @click="handleClick" />
</template>

<script setup lang="ts">
import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import VChart from 'vue-echarts'
import { ref } from 'vue'

type sectionsInfo = {
  name: string
  color: string
}

export type ChartSections = {
  info: sectionsInfo[]
  data: number[][]
  xAxisLabels: string[]
}

type PerformanceChartProps = {
  sections: ChartSections
}

const calculateTotal = (data: number[][]) => {
  const totals = new Array(data[0].length).fill(0)

  data.forEach((subArray) => {
    subArray.forEach((value, index) => {
      totals[index] += value
    })
  })

  return totals
}

const emit = defineEmits<{
  (e: 'sectionClick', value: any): void
}>()

const props = defineProps<PerformanceChartProps>()

const totals = calculateTotal(props.sections.data)

const labelParser = (params: any) => {
  const { dataIndex, value } = params
  if (value === 0) {
    return ''
  }
  if (dataIndex === 0) {
    return `${value} (${Math.round((value * 100) / totals[dataIndex])}%)`
  }

  return `${Math.round((value * 100) / totals[dataIndex])}%`
}

use([CanvasRenderer, BarChart, TooltipComponent, LegendComponent, GridComponent])

const handleClick = (section: any) => {
  emit('sectionClick', section)
}

const grid = {
  left: 50,
  right: 50,
  top: 75,
  bottom: 25
}

const series = props.sections.info.map((section, sid) => {
  return {
    name: section.name,
    type: 'bar',
    stack: 'total',
    barWidth: '70%',
    color: props.sections.info[sid].color,
    label: {
      show: true,
      formatter: (params: any) => {
        return labelParser(params)
      }
    },
    data: props.sections.data[sid].map((data) => data)
  }
})

const option = ref({
  legend: {
    orient: 'horizontal',
    center: 'center',
    textStyle: {
      fontSize: 10,
      fontWeight: 400
    },
    selectedMode: false
  },
  grid,
  yAxis: {
    type: 'value'
  },
  xAxis: {
    data: props.sections.xAxisLabels
  },
  series
})
</script>

<style scoped>
.chart {
  height: 26rem;
}
</style>
