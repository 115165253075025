import { ref } from 'vue'

const key = ref<string | undefined>(undefined)

export const useProductFruit = () => {
  const setProductFruitKey = (code: string) => {
    key.value = code
  }

  return {
    setProductFruitKey,
    key
  }
}

// export type AppSettingStore = ReturnType<typeof useAppSetting>
