import { reactive } from 'vue'

type FilterState = {
  searchValue: string
  isAssignedToMe: boolean
  quickFilterActiveId: string
}
const state = reactive<FilterState>({
  searchValue: '',
  isAssignedToMe: false,
  quickFilterActiveId: ''
})
export const useFilters = () => {
  const updateSearchValue = (value: string) => {
    state.searchValue = value
    state.isAssignedToMe = false
    state.quickFilterActiveId = ''
  }

  const updateIsAssignedToMe = (value: boolean) => {
    state.isAssignedToMe = value
    state.searchValue = ''
  }

  const updateQuickFilterActiveId = (value: string) => {
    state.quickFilterActiveId = value
    state.searchValue = ''
  }

  return {
    state,
    updateSearchValue,
    updateIsAssignedToMe,
    updateQuickFilterActiveId
  }
}
