import { defineStore } from 'pinia'
import { type DealersClient } from 'lead-tray-api-ts'
import { useTranslator } from 'sima-suite-translator-vue3'
import type { State } from './types'
import { createARelevantMessage } from '@/utils'
import { SAlertType } from 'sima-suite-components-vue3'

let dealersClient: DealersClient
export const initDealersClient = (client: DealersClient) => {
  dealersClient = client
}

const { translate } = useTranslator()

export const useDealersStore = defineStore({
  id: 'dealers',
  state: (): State => ({
    dealersList: []
  }),
  actions: {
    async getDealers() {
      try {
        const { dealers } = await dealersClient.get()
        this.dealersList = dealers.map((dealer) => ({ ...dealer, isSelected: true }))
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    }
  },
  getters: {
    getDealersByNames(): string[] {
      return this.dealersList.map((dealer) => dealer.name)
    },
    getDealersBySelected(): string[] {
      return this.dealersList.filter((dealer) => dealer.isSelected).map((dealer) => dealer.id)
    }
  }
})
