import { reactive } from 'vue'

type Toolbar = {
  title?: string
  breadcrumb: {
    isEnable: boolean
    items: {
      text: string
      viewName?: string
    }[]
  }
}

const toolbar = reactive<Toolbar>({
  title: '',
  breadcrumb: {
    isEnable: false,
    items: []
  }
})

export const useBreadcrumb = () => {
  return { toolbar }
}
