import { createApp } from 'vue'
import { createPinia } from 'pinia'
import 'sima-suite-components-vue3/dist/style.css'
import './assets/fonts/fonts.css'
import 'sima-suite-components-vue3/dist/icons.css'
import './assets/tailwind.css'
import App from './App.vue'
import { getRouter } from './router'
import { configureSimaSuiteComponents } from 'sima-suite-components-vue3'
import { loadConfiguration } from './configuration'
import { provideAuthentication } from 'sima-suite-authentication'
import { configureTranslations } from 'sima-suite-translator-vue3'
import translations from './translations'
import { createGtm } from '@gtm-support/vue-gtm'
import { DashboardClient, DealersClient, LeadsClient } from 'lead-tray-api-ts'
import {
  initDealersClient,
  initLeadClientCommunicationAttempts,
  initLeadsClient,
  useUserStore
} from '@/store'
import VueHotjar from 'vue-hotjar-next'
import packageInfo from '../package.json'
import { initMonitoring, Release } from './plugins/sentry'
import ProductTheme from '../leadtray.schema.json'
import { initDashboardClient, initDashboardLeadsClient } from '@/store/Dashboard'
import { initIntercept } from '@/plugins/FetchBackendInterceptor'
import { useProductFruit } from '@/composables/useProductFruit'

const { version, name } = packageInfo
configureTranslations(translations)
const {
  authentication,
  backendUrl,
  hotjarKey,
  canManageAppointment,
  gtmKey,
  sentryKey,
  productFruitsWorkspaceKey,
  isServiceKeyVisible
} = await loadConfiguration()

initLeadsClient(new LeadsClient(backendUrl))
initLeadClientCommunicationAttempts(new LeadsClient(backendUrl))
initDealersClient(new DealersClient(backendUrl))
initDashboardLeadsClient(new LeadsClient(backendUrl))
initDashboardClient(new DashboardClient(backendUrl))

authentication.silentRenew = ''
provideAuthentication({ ...authentication, allowLogs: import.meta.env.MODE === 'development' })

const { setProductFruitKey } = useProductFruit()

setProductFruitKey(productFruitsWorkspaceKey)

const app = createApp(App)
app.use(createPinia())
initIntercept(backendUrl)
const router = getRouter()

const userStore = useUserStore()
userStore.canManageAppointment = canManageAppointment
userStore.isServiceKeyVisible = isServiceKeyVisible

app.use(router)

configureSimaSuiteComponents(app, ProductTheme)

if (import.meta.env.PROD) {
  initMonitoring(app, {
    release: new Release(name, version),
    key: sentryKey,
    dsn: 'https://7b2e9ef50d6cc1cee34c1674f13cbeeb@o1427692.ingest.sentry.io/4505984983498752',
    router,
    url: backendUrl,
    productionEnvironments: ['production', 'staging'],
    environment: backendUrl.includes('-qa-') ? 'staging' : 'production'
  })

  app.use(VueHotjar, {
    id: Number(hotjarKey),
    isProduction: import.meta.env.PROD,
    snippetVersion: 6
  })

  app.use(
    createGtm({
      id: gtmKey,
      vueRouter: router
    })
  )
}

app.mount('#app')
