import * as Sentry from '@sentry/vue'
import type { Router } from 'vue-router'
import type { App } from 'vue'

export class Release {
  constructor(
    private name: string,
    private version: string
  ) {}

  getReleaseName = (): string => `${this.name}@${this.version}`
}

export interface MonitoringConfig {
  release: Release
  key: string
  dsn: string
  router: Router
  url: string
  productionEnvironments: string[]
  environment: string
}
export function initMonitoring(app: App<Element>, config: MonitoringConfig) {
  const { release, dsn, router, url } = config

  Sentry.init({
    app,
    dsn,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    release: release.getReleaseName(),
    logErrors: false,
    tracesSampleRate: 0.8,
    tracePropagationTargets: ['localhost', url],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
