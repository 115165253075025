import type {
  FilterValue,
  ILeadDetailsDto,
  IGetLeadsQueryResult,
  IVehicleAdditionalDataDto
} from 'lead-tray-api-ts'

export type State = {
  leadsList: IGetLeadsQueryResult[]
  currentLead: ILeadDetailsDto
  vehicleInformation?: IVehicleAdditionalDataDto[]
  totalPages: number
  currentPage: number
  filters: LeadsFilter[]
}

export interface LeadsFilter {
  filter: FilterValue
  type: LeadsFiltersType
}

export enum LeadsFiltersType {
  BY_STATUS = 'status',
  BY_USER = 'byUser',
  BY_DEALER = 'byDealer'
}
