import { defineStore } from 'pinia'
import type { State } from '@/store/CommunicationAttempts/types'
import { createARelevantMessage } from '@/utils'
import { useTranslator } from 'sima-suite-translator-vue3'
import {
  CancelRequest,
  ClosingReasonEnumRequest,
  ClosingReasonRequest,
  CommunicationAttemptsDto,
  type LeadsClient,
  ScheduleRequest
} from 'lead-tray-api-ts'
import { SAlertType } from 'sima-suite-components-vue3'

let leadsClient: LeadsClient
export const initLeadClientCommunicationAttempts = (client: LeadsClient) => {
  leadsClient = client
}

const { translate } = useTranslator()

export const useCommunicationAttempts = defineStore({
  id: 'communicationAttempts',
  state: (): State => ({
    form: {
      notes: '',
      channel: '',
      isPostponed: false,
      postponedDate: undefined,
      appointmentDate: undefined,
      closingReason: {
        value: ClosingReasonEnumRequest.CustomerNotReachable,
        notes: ''
      }
    }
  }),
  actions: {
    async saveCommunicationAttempts(leadId: string, agentName: string) {
      try {
        const requestData = new CommunicationAttemptsDto({
          contactType: this.form.channel,
          notes: this.form.notes,
          postponeDate: this.form.isPostponed ? this.form.postponedDate : undefined,
          agentName,
          contactDate: new Date()
        })
        await leadsClient.communicationAttempts(leadId, requestData)
        this.$reset()
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    },
    async closeLeadWithAppointment(leadId: string) {
      try {
        const scheduleData = new ScheduleRequest({
          appointmentDate: this.form.appointmentDate!,
          contactDate: new Date(),
          contactType: this.form.channel,
          notes: this.form.notes
        })
        await leadsClient.schedule(leadId, scheduleData)
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    },
    async closeLeadWithoutAppointment(leadId: string) {
      try {
        const cancelRequest = new CancelRequest({
          closingReason: new ClosingReasonRequest({
            value: ClosingReasonEnumRequest[this.form.closingReason.value],
            notes:
              this.form.closingReason.value === ClosingReasonEnumRequest.Others
                ? this.form.notes
                : ''
          }),
          contactDate: new Date(),
          contactType: this.form.channel,
          notes: this.form.notes
        })
        await leadsClient.cancel(leadId, cancelRequest)
        this.$reset()
      } catch (error) {
        createARelevantMessage(translate('App.Common.ErrorMessage'), SAlertType.ERROR)
      }
    }
  }
})
