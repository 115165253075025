<template>
  <svg
    width="66"
    height="65"
    viewBox="0 0 66 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    title="default icon"
  >
    <g filter="url(#filter0_d_217_1438)">
      <path
        d="M44.1353 44C43.3038 44 42.5063 44.2634 41.9183 44.7322C41.3303 45.2011 41 45.837 41 46.5C41 47.163 41.3303 47.7989 41.9183 48.2678C42.5063 48.7366 43.3038 49 44.1353 49H46.8647C47.6962 49 48.4937 48.7366 49.0817 48.2678C49.6697 47.7989 50 47.163 50 46.5C50 45.837 49.6697 45.2011 49.0817 44.7322C48.4937 44.2634 47.6962 44 46.8647 44H44.1353Z"
        fill="#EBEBEC"
      />
    </g>
    <g filter="url(#filter1_i_217_1438)">
      <path
        d="M33.9155 0.0114418C29.4308 -0.105714 24.9688 0.677866 20.8016 2.31441C16.6344 3.95096 12.8493 6.40618 9.67703 9.53041C6.50474 12.6546 4.01174 16.3824 2.35002 20.4865C0.688294 24.5905 -0.107341 28.9849 0.0116178 33.4017C0.464213 50.5314 15.8525 65 33.558 65H60.6594C62.0747 64.9965 63.431 64.4412 64.4318 63.4556C65.4326 62.4699 65.9964 61.1342 66 59.7403V33.0584C66 15.6123 51.3088 0.488381 33.9155 0.0114418ZM54.0424 52.4525C54.0424 52.6535 53.9614 52.8462 53.8171 52.9883C53.6728 53.1304 53.4771 53.2102 53.273 53.2102H33.5535C21.8629 53.2102 12.1819 44.086 11.9737 32.8757C11.9269 30.1256 12.436 27.3937 13.4713 24.8392C14.5067 22.2847 16.0476 19.9585 18.0045 17.996C19.9613 16.0335 22.295 14.474 24.8696 13.4082C27.4443 12.3424 30.2085 11.7916 33.0013 11.7878H33.3996C44.7823 11.9929 54.0424 21.5272 54.0424 33.0406V52.4525Z"
        fill="#EBEBEC"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_217_1438"
        x="37"
        y="44"
        width="17"
        height="13"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_217_1438" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_217_1438"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_i_217_1438"
        x="0"
        y="0"
        width="66"
        height="69"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_217_1438" />
      </filter>
    </defs>
  </svg>
</template>
